<template>
<body class="menu-position-side menu-side-left full-screen with-content-panel">
    <div ui-view="" autoscroll="true" style="min-height: 100%;" class="ng-scope">
        <div class="all-wrapper menu-side with-pattern ng-scope" ng-class="{'d-none' : !emailSent}" style="padding: 0px !important; background: #09b585; background-size: 100% 100%;">
            <div class="auth-box-w" style="margin-top: 20px">
                <div class="logo-w" style="padding:15%">
                    <a ui-sref="home.main" href="/">
                        <img alt="" src="/assets/img/success.png" height="80px" ng-if="!whiteLabeled" class="ng-scope">
                    </a>
                </div>
                <h4 class="text-center">Active Employee Success</h4>
                <form name="login_form" novalidate="" class="ng-pristine ng-valid">
                    <div class="row">
                        <div class="col text-center">
                            <button class="btn btn-primary" type="button" ui-sref="login" v-on:click="gotoResetPassword()">
                                Reset Password
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

    </div>
</body>
</template>

<script>
import * as External from '@/utils/External.js'
import * as Session from "@/utils/SessionStore";
export default {
    name: "ForgotPassword",
    mounted() {
        External.head_link("/assets/css/tt/styleRegister.css");
        External.head_link("/assets/css/tt/themeRegister.css");
        External.head_link("/assets/css/tt/mainLogin.css");
        External.head_link("/assets/css/tt/style(1)Login.css");
        External.head_link("/assets/css/tt/rocket.css");
    },
    destroyed() {
        External.del_link("/assets/css/tt/styleRegister.css");
        External.del_link("/assets/css/tt/themeRegister.css");
        External.del_link("/assets/css/tt/mainLogin.css");
        External.del_link("/assets/css/tt/style(1)Login.css");
        External.del_link("/assets/css/tt/rocket.css");
    },
    data() {
        return {
        };
    },
    methods: {
        gotoResetPassword() {
            var callbackUrl = Session.get('resetPasswordLink')
            console.log("gotoResetPassword",callbackUrl);
            Session.remove("resetPasswordLink");
            Session.remove("registrationLink");
            window.location.href = callbackUrl;
        }
    }
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
@import '/assets/css/company.css';
@import url("//unpkg.com/element-ui@2.13.1/lib/theme-chalk/index.css");

.pagination {
    justify-content: center;
    text-align: center;
}
</style>
